<template>
    <b-container
        fluid
        class="home_monitoring"
        v-bind:style="{
            height: 'calc(100% - 150px)!important',
        }"
    >
        <b-row>
            <b-col style="margin-top: 10px; margin-bottom: 15px">
                <div>
                    <b-row style="height: 49px">
                        <b-col cols="4" style="float: none; margin: auto">
                            <font-awesome-icon
                                class="button cursor-pointer rotate"
                                v-bind:class="{ down: equipmentListVisible }"
                                icon="fa-solid fa-caret-right"
                                size="lg"
                                :title="$t('Select equipment')"
                                style="margin-left: 10px; margin-right: 10px"
                                role="button"
                                href="#collapseExample"
                                v-b-toggle.collapse-1
                            />

                            <!-- <font-awesome-icon icon="fa-solid fa-caret-right" :title="$t('Select equipment')" v-b-toggle.collapse-1 /> -->
                            <!-- <b-button style="text-transform: none; text-align: center;" v-b-toggle.collapse-1 variant="primary">{{ $t('Select equipment') }}</b-button> -->
                        </b-col>
                        <b-col cols="4" style="float: none; margin: auto">
                            <div style="text-align: center; vertical-align: middle" v-if="$store.state.selectedEquipmentMonitoring != null">{{ $store.state.selectedEquipmentMonitoring }} ({{ selectedEquipmentVariableCount }})</div>
                        </b-col>
                        <b-col cols="4" style="float: none; margin: auto">
                            <div style="text-align: right; vertical-align: middle">
                                <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" v-on:click="displayHelp" :title="$t('Help')" />
                            </div>
                        </b-col>
                        <!-- <b-col>
                        <b-button style="text-transform: none" variant="primary" v-on:click="restartRuntime">{{ $t('monitor.restartRuntime') }}</b-button>
                    </b-col> -->
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-collapse id="collapse-1" class="mt-2" visible v-model="equipmentListVisible">
            <b-row>
                <b-col>
                    <!-- Equipment list grid -->
                    <DxDataGrid
                        :dataSource="dsEquipments"
                        :allow-column-reordering="true"
                        :repaint-changes-only="true"
                        :show-borders="true"
                        :selection="{ mode: 'single' }"
                        :columnAutoWidth="false"
                        :allow-column-resizing="true"
                        v-on:initialized="onEquipmentListInitialized"
                        @selection-changed="onSelectionChangedEquipmentList"
                    >
                        <template #status-equipment="{ data }">
                            <div class="text-center">
                                <!-- ServerState.Stopped -->
                                <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="stop" class="fa-lg" color="#ffca28" />
                                <!-- ServerState.Started -->
                                <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="play" class="fa-lg" color="#4dc615" />
                                <!-- ServerState.StartPending -->
                                <font-awesome-layers v-else-if="data.data.StatusEquipment == 2">
                                    <font-awesome-icon icon="play" class="fa-lg" color="#4dc615" />
                                    <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" />
                                </font-awesome-layers>
                                <!-- ServerState.StopPending -->
                                <font-awesome-layers v-else-if="data.data.StatusEquipment == 3">
                                    <font-awesome-icon icon="stop" class="fa-lg" color="#c72614" />
                                    <font-awesome-icon icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" />
                                </font-awesome-layers>
                            </div>
                        </template>
                        <template #status-communication="{ data }">
                            <div class="text-center">
                                <!-- ComState.Disconnected -->
                                <font-awesome-icon v-if="data.data.StatusEquipment == 0" icon="times-circle" class="fa-lg" color="#c72614" />
                                <!-- ComState.Connected -->
                                <font-awesome-icon v-else-if="data.data.StatusEquipment == 1" icon="check-circle" class="fa-lg" color="#4dc615" />
                                <!-- ComState.Reconnecting -->
                                <font-awesome-icon v-else-if="data.data.StatusEquipment == 2" icon="spinner-third" class="fa-spin-custom fa-lg" color="#c8b900" />
                                <!-- ComState.SubscribeError -->
                                <font-awesome-icon v-else-if="data.data.StatusEquipment == 3" icon="exclamation-circle" class="fa-lg" color="#c66900" />
                            </div>
                        </template>
                        <template #status-datasource="{ data }">
                            <div class="text-center">
                                <!-- DBState.NotConfigured -->
                                <font-awesome-icon v-if="data.data.StatusDataSource == 0" icon="empty-set" class="fa-lg" />
                                <!-- DBState.Disconnected -->
                                <font-awesome-icon v-else-if="data.data.StatusDataSource == 1" icon="times-circle" class="fa-lg" color="#c72614" />
                                <!-- DBState.Connected -->
                                <font-awesome-icon v-else-if="data.data.StatusDataSource == 2" icon="check-circle" class="fa-lg" color="#4dc615" />
                                <!-- DBState.BackupConnected -->
                                <font-awesome-icon v-else-if="data.data.StatusDataSource == 3" icon="exclamation-circle" class="fa-lg" color="#c66900" />
                            </div>
                        </template>
                        <template #active="{ data }">
                            <div class="text-center">
                                <b-form-checkbox v-model="data.data.Active" disabled></b-form-checkbox>
                            </div>
                        </template>
                        <template #data-left="{ data }">
                            <div class="text-left">
                                {{ data.displayValue + ' (' + data.data.ItemCount + ')' }}
                            </div>
                        </template>
                        <DxFilterRow :visible="true" />

                        <DxColumnChooser :enabled="true" mode="select" />
                        <DxColumn data-field="Name" :visible="true" :caption="$t('Name')" alignment="center" cell-template="data-left" />
                        <DxColumn data-field="Status Equipment" :allowFiltering="false" :visible="true" cell-template="status-equipment" :caption="$t('Equipment')" alignment="center" />
                        <DxColumn data-field="Status Communication" :allowFiltering="false" :visible="true" cell-template="status-communication" :caption="$t('Communication')" alignment="center" />
                        <DxColumn data-field="Status Data Source" :allowFiltering="false" :visible="true" cell-template="status-datasource" :caption="$t('Data Source')" alignment="center" />
                        <DxColumn data-field="Active" :allowFiltering="false" :visible="true" cell-template="active" :caption="$t('Active')" alignment="center" />
                        <DxColumn data-field="Type" :allowFiltering="false" :group-index="0" data-type="string" />
                    </DxDataGrid>
                </b-col>
            </b-row>
        </b-collapse>
        <!-- Only display data source if connection has started -->
        <b-row style="margin-top: 7px" v-if="connectionStarted">
            <b-col>
                <!-- @saving="saveEdit" -->
                <DxDataGrid
                    :dataSource="dataSource"
                    :allow-column-reordering="true"
                    :repaint-changes-only="true"
                    :show-borders="true"
                    :highlight-changes="true"
                    :selection="{ mode: 'single' }"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                    @saving="saveEdit"
                    @row-prepared="onRowPrepared"
                    @selection-Changed="selectionChanged"
                    @optionChanged="onOptionChanged"
                >
                    <DxFilterRow :visible="true" />
                    <DxScrolling mode="Standard" />
                    <DxPaging :enabled="true" :page-size="pageSize" :visible="true" />
                    <DxPager :allowed-page-sizes="[10, 20, 40, 100, 250, 500]" :show-page-size-selector="true" :show-info="true" :visible="true" />
                    <DxEditing :allow-updating="loggedIn && $store.state.auth.user.permissions.PermissionWriteRun" :allow-adding="false" :allow-deleting="false" :use-icons="true" mode="row" />
                    <DxColumnChooser :enabled="true" mode="select" />
                    <template #trend-template="{ data }">
                        <div class="text-center">
                            <span v-if="data.data.Type != 'System.Boolean'">
                                <font-awesome-icon v-if="data.data.Tendance > 0" icon="arrow-alt-up" class="fa-lg rotate-45" />
                                <font-awesome-icon v-else-if="data.data.Tendance < 0" icon="arrow-alt-down" class="fa-lg rotate--45" />
                                <font-awesome-icon v-else icon="arrow-alt-right" class="fa-lg" />
                            </span>
                            <span v-else>
                                <font-awesome-icon v-if="data.data.Tendance < 0" icon="arrow-alt-up" class="fa-lg" />
                                <font-awesome-icon v-else-if="data.data.Tendance > 0" icon="arrow-alt-down" class="fa-lg" />
                                <font-awesome-icon v-else icon="arrow-alt-right" class="fa-lg" />
                            </span>
                        </div>
                    </template>
                    <template #title-header="{ data }">
                        <div :title="data.column.caption" class="text-center">
                            {{ data.column.caption }}
                        </div>
                    </template>
                    <template #data-left="{ data }">
                        <div :title="data.displayValue" class="text-left">
                            {{ data.displayValue }}
                        </div>
                    </template>
                    <template #data-center="{ data }">
                        <div :title="data.displayValue" class="text-center">
                            {{ data.displayValue }}
                        </div>
                    </template>
                    <template #data-right="{ data }">
                        <div :title="data.displayValue" class="text-right">
                            {{ data.displayValue }}
                        </div>
                    </template>
                    <template #data-value="{ data }">
                        <div :title="data.data.Valeur" class="text-right myOverflow">
                            <div>
                                <!--  Pour une valeur booleenne => possibilité de choisir le type de bit (0/1, true/false, opened/closed, yes/no) -->
                                {{ data.data.Valeur }}
                            </div>
                        </div>
                    </template>
                    <template #data-valuen1="{ data }">
                        <div :title="data.data.ValeurN1" class="text-right myOverflow">
                            <div>
                                {{ data.data.ValeurN1 }}
                            </div>
                        </div>
                    </template>
                    <template #data-hd="{ data }">
                        <div class="text-center">
                            {{ format_date(data.displayValue, 'DD/MM/YYYY HH:mm:ss') }}
                        </div>
                    </template>
                    <template #data-duration="{ data }">
                        <!-- Pouvoir swapper entre 2 modes: affichage simple / affichage complet (qqs secondes / 12 secondes 3 milliemes) -->
                        <div class="text-center">
                            {{ format_duration(data.displayValue, 'HH:mm:ss') }}
                        </div>
                    </template>
                    <template #data-group="{ data }">
                        <p v-if="data?.value == 'Default'">{{ $t('Default') }} ({{ data.data.items?.length }})</p>
                        <p v-else>{{ data?.value?.replace('_', ' ') }} ({{ data.data.items?.length }})</p>
                    </template>
                    <template #displayModeTemplate>
                        <b-form-select id="durationDisplayMode" v-model="selectedDurationDisplayMode" :options="availableDurationDisplayModes"> </b-form-select>
                    </template>
                    <template #arrayTemplate="{ data: array }">
                        <div>
                            <MonitorDetailArray ref="MonitorDetailArray" :templateData="array" :durationMode="selectedDurationDisplayMode" />
                        </div>
                    </template>
                    <DxToolbar>
                        <DxItem location="after" template="displayModeTemplate" />
                        <DxItem name="columnChooserButton" locate-in-menu="auto" location="after" />
                    </DxToolbar>
                    <DxColumn :group-index="1" data-field="Groupe" data-type="string" alignment="center" group-cell-template="data-group" />
                    <DxColumn data-field="key" data-type="string" :visible="false" :allow-editing="false" caption="Key" alignment="center" cell-template="data-left" />
                    <DxColumn data-field="Nom" data-type="string" :allow-editing="false" :caption="$t('Name')" alignment="center" cell-template="data-left" />
                    <DxColumn data-field="Type" data-type="string" :allow-editing="false" alignment="center" cell-template="data-left" />
                    <DxMasterDetail ref="Mdetail" :enabled="false" template="arrayTemplate"></DxMasterDetail>
                    <DxColumn data-field="Valeur" data-type="string" :allow-editing="true" :caption="$t('Value')" alignment="center" cell-template="data-value" />
                    <!-- Only allow edit 'Valeur' attribute -->
                    <DxColumn data-field="HD" data-type="datetime" :allow-editing="false" :caption="$t('HD')" alignment="center" cell-template="data-hd" />
                    <DxColumn data-field="ValeurN1" data-type="string" :visible="true" :allow-editing="false" :caption="$t('ValueN1')" alignment="center" cell-template="data-valuen1" />
                    <DxColumn data-field="Minimum" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Maximum" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire1" :allowFiltering="false" :visible="true" data-type="string" :allow-editing="false" :caption="$t('DurationCol')" alignment="center" cell-template="data-duration" />
                    <DxColumn data-field="CompteurHoraire1Cumulé" :allowFiltering="false" :visible="true" data-type="string" :allow-editing="false" :caption="$t('Cumulative Duration')" alignment="center" cell-template="data-duration" />
                    <DxColumn data-field="Tendance" :allowFiltering="false" :visible="true" data-type="string" :allow-editing="false" cell-template="trend-template" :caption="$t('Trend')" alignment="center" />
                    <!-- <DxColumn data-field="CompteurHoraire2" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire2Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire3" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire3Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire4" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire4Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire5" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire5Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire6" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire6Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire7" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire7Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire8" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire8Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire9" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire9Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire10" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraire10Cumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurIncrementation" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="variationIncrementation" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement2" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement3" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement4" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement5" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement6" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement7" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement8" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement9" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement10" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="HorsMaximum" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="HorsMinimum" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Inactif" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement1N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement2N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement3N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement4N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement5N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement6N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement7N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement8N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement9N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Evenement10N1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="HorsMaximumN1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="HorsMinimumN1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="InactifN1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="HDMin" :visible="false" data-type="datetime" :allow-editing="false" />
                    <DxColumn data-field="HDMax" :visible="false" data-type="datetime" :allow-editing="false" />
                    <DxColumn data-field="ALMin" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="ALMax" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="ALMinN1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="ALMaxN1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="EcartType" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Moyenne" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="mirroring" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireALMax" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireALMaxCumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireALMin" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireALMinCumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireMax" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireMaxCumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireMin" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="CompteurHoraireMinCumulé" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence1" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence2" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence3" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence4" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence5" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence6" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence7" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence8" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence9" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Occurence10" :visible="false" data-type="string" :allow-editing="false" />
                    <DxColumn data-field="Publish" :visible="false" data-type="string" :allow-editing="false" /> -->
                    <!-- <DxColumn caption="Dynamique" cell-template="chart-cell-template" :min-with="320"/> -->
                    <!-- <DxColumn cell-template="state-template" group-cell-template="grouped-state-template" :caption="$t('State')" width="50" /> -->
                </DxDataGrid>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Action from '@/components/configuration/Actions/Action.vue';
import DataSource from '@/components/configuration/DataSources/DataSource.vue';
import ProdComMonitoringService from '@/services/prodcom.monitoring.service.js';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ToastAlert from '@/utils/ToastAlert';
import constants from '@/utils/constants';
import dayjs from 'dayjs';
import moment from 'moment';
import ChartCell from '@/components/ChartCell.vue';

import { DxDataGrid, DxColumn, DxGrouping, DxGroupPanel, DxSearchPanel, DxPaging, DxPager, DxToolbar, DxItem,DxFilterRow , DxScrolling, DxColumnChooser, DxEditing, DxMasterDetail } from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import CustomStore from 'devextreme/data/custom_store';
import MonitorDetailArray from './MonitorDetailArray.vue';
export default {
    name: 'Monitor',
    components: {
        Action,
        DataSource,
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxPaging,
        DxScrolling,
        DxPager,
        DxSearchPanel,
        DxDataGrid,
        DxToolbar,
        DxItem,
        DxColumnChooser,
        DxEditing,
        ChartCell,
        DxButton,
        DxMasterDetail,
        DxFilterRow,
        MonitorDetailArray,
    },
    data() {
        return {
            dsEquipments: null,
            dataGridEquipmentList: null,
            isIframe: window.location !== window.parent.location,
            timerRefreshEquipments: null,
            equipmentListVisible: true,
            hubConnection: null,
            dataSource: null,
            connectionStarted: false,
            selectedEquipmentVariableCount: 0,
            availableDurationDisplayModes: [
                { value: 0, text: this.$t('displayMode.simplified') },
                { value: 1, text: this.$t('displayMode.full') },
            ],
            selectedDurationDisplayMode: null,
            arrayOppened: '',
            items: [],
            pageSize: localStorage.getItem('pageSize') ? parseInt(localStorage.getItem('pageSize')) : 20
        };
    },
    async mounted() {
        this.selectedDurationDisplayMode = this.availableDurationDisplayModes[0].value;
        // Get the list of equipments at startup of component, and automatically select the first one.
        await this.fetchEquipmentList();
        if (this.$store.state.selectedEquipmentMonitoring == null && this.dsEquipments?.length > 0) {
            const foundEquipment = this.dsEquipments.find((e) => e.StatusEquipment == 1)?.Name ?? this.dsEquipments[0]?.Name;
            this.$store.commit('selectEquipmentMonitor', foundEquipment);
        }
        if (this.$store.state.selectedEquipmentMonitoring != null) {
            var selectedRow = this.dsEquipments.find((val) => val.Name == this.$store.state.selectedEquipmentMonitoring);
            this.dataGridEquipmentList.selectRows(selectedRow);
        }
        this.equipmentListVisible = false;
    },
    created() {
        // Set the different background tasks:
        // - Getting the list and status of every equipment
        // - If an equipment is selected, get its list of variables
        // - Report other ProdCom monitoring data treme
        this.timerRefreshEquipments = setInterval(async () => {
            await this.fetchEquipmentList();
            /*this.dsEquipments.forEach(element => {
                if(element.Name == "R0 Bloc" || element.Name == "R2 Bloc"){
                    console.log(element.Name + " -> " +  element.StatusCommunication + " " +  element.StatusEquipment)
                }
            });*/

        }, 1000);
    },
    beforeDestroy() {
        // Make sure we correctly stop the different background tasks here so that
        // no API calls are done where none should be made.
        clearInterval(this.timerRefreshEquipments);
        this.dsEquipments = null;
        this.stopMonitoring();
    },
    methods: {
        /**
         * Formats the date to the given format for correct display in the data grids.
         */
        format_date(value, format) {
            if (value) {
                return dayjs(value).format(format);
            } else {
                return 'no value';
            }
        },
        selectionChanged(e) {
            e.component.collapseAll(-1);
            this.arrayOppened = '';
            if (e.selectedRowsData[0].Type.endsWith('[]')) {
                e.component.expandRow(e.currentSelectedRowKeys[0]);
                this.arrayOppened = e.selectedRowsData[0].key;
            }
        },
        format_duration(value, format) {
            if (value) {
                return this.selectedDurationDisplayMode == 0 ? (value == '00:00:00' ? '-' : moment.duration(value).humanize()) : this.formatTimeWithThreeDecimals(value);
            } else {
                return 'no value';
            }
        },
        formatTimeWithThreeDecimals(timeString) {
            if (timeString == '00:00:00') return timeString;
            let [hours, minutes, secondsandmili] = timeString.split(':');
            let [seconds, milliseconds] = secondsandmili.split('.');
            milliseconds = milliseconds.substring(0, 3);
            let formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds.padStart(3, '0')}`;
            return formattedTime;
        },
        onRowPrepared(e) {
            if (e.rowType == 'data') {
                if (e.data.IsSimulated) {
                    e.rowElement.style.color = 'var(--primary)';
                }
            }
        },
        onOptionChanged(e) {
            if (e.fullName === 'paging.pageSize' && e.value != null) {
                if(this.pageSize != e.value){
                    this.pageSize = e.value;
                    localStorage.setItem('pageSize', e.value);
                }
                
            }
        },
        /**
         * Event called when modifying a value in the Item list. Sends the value back to ProdCom backend.
         */
        async saveEdit(e) {
            var newValue = e.changes[0].data.Valeur;
            var key = e.changes[0].key;
            // Get data type of the variable
            var type = this.items.find((e) => e.key == key).Type;
            if (type == 'DateTime' && newValue != null && newValue != undefined && newValue != '') {
                let formattedStr = newValue.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})(\.\d{3})?/, (match, day, month, year, hour, minute, second, millis) => `${year}-${month}-${day}T${hour}:${minute}:${second}${millis || '.000'}Z`);
                newValue = new Date(formattedStr);
                // Remove navigator system timezone offset
                newValue = new Date(newValue.getTime() + newValue.getTimezoneOffset() * 60000);
            }
            if (key.startsWith('ProdCom_')) return; // Don't allow editing the formulas. Maybe add a warning popup ?
            const data = await ProdComMonitoringService.setItem(this.$store.state.selectedEquipmentMonitoring, key, newValue);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        },
        /**
         * Stores the reference to the Equipment data grid
         */
        async onEquipmentListInitialized(e) {
            this.dataGridEquipmentList = e.component;
        },

        /**
         * Event fired when changing the selected equipment
         */
        async onSelectionChangedEquipmentList({ selectedRowsData }) {
            this.$store.commit('selectEquipmentMonitor', selectedRowsData[0].Name);
            // When changing equipment, unselect the previous one and cut the SignalR connection to recreate it
            // with the new equipment (the callback will be named based on the equipment name)
            this.stopMonitoring();

            // Recreate the SignalR connection hub
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(this.$store.state.settings.API_URL + `/monitoring?equipmentName=${selectedRowsData[0].Name}&clientType=${constants.MONITOR_CLIENT_TYPE_WEB_MONITORING}`, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build();
            // Recreate the datasource and load initial data
            const store = new CustomStore({
                load: async () => {
                    var response = await ProdComMonitoringService.getItems(this.$store.state.selectedEquipmentMonitoring, [
                        constants.TVI_Groupe,
                        constants.TVI_Nom,
                        constants.TVI_Valeur,
                        constants.TVI_ValeurN1,
                        constants.TVI_Minimum,
                        constants.TVI_Maximum,
                        constants.TVI_HD,
                        constants.TVI_CompteurHoraire1,
                        constants.TVI_CompteurHoraire1Cumule,
                        constants.TVI_Tendance,
                    ]);
                    this.selectedEquipmentVariableCount = response.ret.length;
                    if (response.success == 'y') {
                        this.items = response.ret;
                        // this.items.forEach((element) => {
                        //     if (element.Type == 'System.DateTime') {
                        //         element.Valeur = new Date(element.Valeur);
                        //         element.ValeurN1 = new Date(element.ValeurN1);
                        //     }
                        // });
                        return response.ret;
                    } else {
                        // Avoid displaying an error when equipment not started.
                        return [];
                    }
                },
                update: async (key, value) => {},
                key: 'key',
            });

            // Start connection
            await this.hubConnection.start();

            this.hubConnection.on(constants.MONITOR_RECEIVE_CLIENTS_COUNT, (data) => {});
            this.hubConnection.on(constants.MONITOR_ACTIONUPDATE, (data) => {});
            // Real time update: update the data source with push / update method.
            this.hubConnection.on(constants.MONITOR_VALUEUPDATE, (data) => {
                // Only process data for the selected equipment. We can discard every other equipment as it will not be displayed.
                if (data.Equipment == this.$store.state.selectedEquipmentMonitoring && data.Items !== null && data.Items !== undefined) {
                    for (var it of data.Items) {
                        if (it == null || it == undefined) continue; // Make sure data actually exists
                        store.push([{ type: 'update', key: it.key, data: it }]);
                        if (this.$refs.MonitorDetailArray != null && this.$refs.MonitorDetailArray != undefined) {
                            if (it.key == this.arrayOppened) {
                                this.$refs.MonitorDetailArray.update(it.key, it);
                            }
                        }
                    }
                }
            });
            // Reassign datasource
            this.dataSource = store;
            this.connectionStarted = true;
        },

        /**
         * Closes the SignalR connection and cleans up the object.
         */
        stopMonitoring() {
            if (this.hubConnection != null) {
                this.hubConnection.stop();
                this.hubConnection = null;
            }
            this.connectionStarted = false;
        },
        /**
         * Periodically fetch the equipment status from REST API
         */
        async fetchEquipmentList() {
            if (!this.equipmentListVisible) return;
            const data = await ProdComMonitoringService.getEquipments();
            if (data.success == 'y') {
                if (this.dsEquipments == null) this.dsEquipments = data.ret;
                else {
                    // Update the value of every item in the list from the received data.
                    data.ret.forEach((e) => {
                        this.dsEquipments.find((eq) => {
                            if (eq.Name == e.Name) {
                                eq.StatusEquipment = e.StatusEquipment;
                                eq.StatusCommunication = e.StatusCommunication;
                                eq.StatusDataSource = e.StatusDataSource;
                                eq.Active = e.Active;
                                eq.Type = e.Type;
                            }
                        });
                    });
                }
            }
        },
        /**
         * Restarts the runtime. Not correctly implemented in backend. Do not use.
         */
        async restartRuntime(e) {
            const data = await ProdComMonitoringService.restartRuntime();
            if (data.success == 'y') {
            }
        },
        async displayHelp() {
            this.$store.commit('displayHelp', this.$t('help.run.variables'));
            this.$bvModal.show('bv-modal-help');
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn && this.$store.state.auth.user != null && this.$store.state.auth.user.email != '';
        },
    },
};
</script>

<style lang="scss">
@keyframes spinner_anim {
    to {
        transform: rotate(360deg);
    }
}
.myOverflow{
    max-width: 32em;
    overflow:hidden;
}
.fa-spin-custom {
    animation: spinner_anim 2s linear infinite;
}
.tabHeaderIcon {
    display: inline-block;
}
.tabHeaderText {
    width: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tabGlobal {
    padding: 0;
    height: calc(100% - 28px);
    background-color: --main-background-color;
}
.tabContent {
    min-height: 100%;
    height: 100%;
}
.dx-datagrid-rowsview .order {
    text-align: center !important;
}
.rotate {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.rotate.down {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell {
    padding: 0 0 0 30px;
}
</style>

<!-- 
option globale: afficher ou non les millièmes. (long à répercuter sur tout les écran)

Monitoring
  - Onglet: afficher le nombre de variables monitorés. (idem sur tout les autres onglets)

icone initiales: passer icone pour delogger en popup quand clic sur initiales

Passer en 23.1.0.0
 -->
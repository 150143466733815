import utils from "@/utils";
import { eq } from "lodash";

class ProdComEquipmentService {
    async reloadConfiguration(projectId) {
        return await utils.PostQuery('equipments.ReloadConfiguration');
    }

    async getAllEquipments(projectId, includeActions = true) {
        return await utils.PostQuery('equipments.GetEquipmentList', { Id: projectId, IncludeActions: includeActions });
    }

    async deleteAllEquipments(projectId) {
        return await utils.PostQuery('equipments.DeleteAllEquipments', { Id: projectId });
    }

    async enableAllEquipments(projectId) {
        return await utils.PostQuery('equipments.EnableAllEquipments', { Id: projectId });
    }

    async disableAllEquipments(projectId) {
        return await utils.PostQuery('equipments.DisableAllEquipments', { Id: projectId });
    }

    async getEquipmentConfiguration(projectId, equipmentName) {
        return await utils.PostQuery('equipments.GetEquipmentConfiguration',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async getGroups(equipmentName, projectId) {
        return await utils.PostQuery('equipments.GetGroupList',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async getEquipmentVariables(equipmentName, projectId) {
        return await utils.PostQuery('equipments.GetEquipmentVariables',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async getGroupListDetailed(equipmentName, projectId) {
        return await utils.PostQuery('equipments.GetGroupListDetailed',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async getVariableDetails(equipmentName, projectId, groupName, variableName) {
        return await utils.PostQuery('equipments.GetVariableDetails',
            {
                EquipmentName: equipmentName,
                Id: projectId,
                Group: groupName,
                Variable: variableName
            });
    }
    async addInternalVariable(equipmentName, projectId, variableName, variableType, formula, description) {
        return await utils.PostQuery('equipments.addInternalVariable',
            {
                EquipmentName: equipmentName,
                ProjectId: projectId,
                variableName: variableName,
                formula: formula,
                type: variableType,
                description: description
            });
    }
    async deleteInternalVariable(equipmentName, projectId, variableName) {
        return await utils.PostQuery('equipments.deleteInternalVariable',
            {
                EquipmentName: equipmentName,
                ProjectId: projectId,
                variableName: variableName,
            });
    }

    async setVariableDetails(equipmentName, projectId, groupName, variableName, variableDetails) {
        return await utils.PostQuery('equipments.SetVariableDetails',
            {
                EquipmentName: equipmentName,
                Id: projectId,
                Group: groupName,
                Variable: variableName,
                Details: variableDetails,
            });
    }

    async setEquipementName(newEq, oldEq, projectId) {
        return await utils.PostQuery('equipments.setEquipementName',
            {
                NewEquipmentName: newEq,
                OldEquipmentName: oldEq,
                Id: projectId
            });
    }
    async getItems(equipmentName, groupName, projectId) {
        return await utils.PostQuery('equipments.GetItemList',
            {
                EquipmentName: equipmentName,
                GroupName: groupName,
                ProjectId: projectId
            });
    }

    async getAvailableEquipmentTypes() {
        return await utils.PostQuery('equipments.GetAvailableEquipmentTypes');
    }

    async createNewEquipment(equipmentData, projectId) {
        return await utils.PostQuery('equipments.CreateEquipment',
            {
                EquipmentType: equipmentData.type,
                EquipmentData: equipmentData,
                Id: projectId
            });
    }

    async updateEquipment(equipmentData, projectId) {
        return await utils.PostQuery('equipments.UpdateEquipment',
            {
                EquipmentType: equipmentData.type,
                EquipmentData: equipmentData,
                Id: projectId
            });
    }

    async deleteEquipment(equipmentName, projectId) {
        return await utils.PostQuery('equipments.DeleteEquipment',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async exportEquipment(equipmentName, projectId) {
        return await utils.PostQuery('equipments.ExportEquipment',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async upgradeManager(equipmentName, projectId) {
        return await utils.PostQuery('equipments.UpgradeManager',
            {
                EquipmentName: equipmentName,
                Id: projectId
            });
    }

    async activateEquipment(projectId, equipmentName, active) {
        return await utils.PostQuery('equipments.ActivateEquipment',
            {
                EquipmentName: equipmentName,
                Id: projectId,
                Active: active,
            });
    }

    async opcDaGetConnexions(type, isRemote, remoteAddress, login, password, domain, specification) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC DA",
                Method: "EnumerateServers",
                Parameters: {
                    OpcVersion: type,
                    IsRemote: isRemote,
                    Specification: specification,
                    RemoteAddress: remoteAddress,
                    Login: login,
                    Password: password,
                    Domain: domain
                }
            });
    }

    async opcDaBrowseItems(type, isRemote, remoteAddress, login, password, domain, specification, connection) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC DA",
                Method: "BrowseItems",
                Parameters: {
                    OpcVersion: type,
                    IsRemote: isRemote,
                    Specification: specification,
                    RemoteAddress: remoteAddress,
                    Login: login,
                    Password: password,
                    Domain: domain,
                    ConnectionName: type == "V1" ? connection : connection.Name,
                    Url: type == "V123" ? connection.Url : null,
                }
            });
    }
    async opcDaCheckVariablesCounter(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC DA",
                Method: "CheckVariablesCounter",
                Parameters: parameters
            });
    }

    async opcDaGetAvailableTypes() {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC DA",
                Method: "GetAvailableTypes"
            });
    }

    async opcUaLdsConnect(address) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC UA",
                Method: "GetLdsConnection",
                Parameters: {
                    Address: address
                }
            });
    }

    async opcUaCheckConnection(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC UA",
                Method: "CheckConnection",
                Parameters: parameters
            });
    }

    async opcUaBrowseItems(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC UA",
                Method: "BrowseItems",
                Parameters: parameters
            });
    }

    async opcUaBrowseNodeItems(parameters, parentNodeId) {
        // Add parentNodeId to parameters
        parameters.ParentNodeId = parentNodeId;
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC UA",
                Method: "BrowseNodeItems",
                Parameters: parameters
            });
    }

    async opcDaBrowseItems(type, isRemote, remoteAddress, login, password, domain, specification, connection) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC DA",
                Method: "BrowseItems",
                Parameters: {
                    OpcVersion: type,
                    IsRemote: isRemote,
                    Specification: specification,
                    RemoteAddress: remoteAddress,
                    Login: login,
                    Password: password,
                    Domain: domain,
                    ConnectionName: type == "V1" ? connection : connection.Name,
                    Url: type == "V123" ? connection.Url : null,
                }
            });
    }

    async opcDaBrowseNodeItems(type, isRemote, remoteAddress, login, password, domain, specification, connection, parentNodeIds) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC DA",
                Method: "BrowseNodeItems",
                Parameters: {
                    OpcVersion: type,
                    IsRemote: isRemote,
                    Specification: specification,
                    RemoteAddress: remoteAddress,
                    Login: login,
                    Password: password,
                    Domain: domain,
                    ConnectionName: type == "V1" ? connection : connection.Name,
                    Url: type == "V123" ? connection.Url : null,
                    parentNodeIds: parentNodeIds
                }
            });
    }

    async opcUaCheckVariablesCounter(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "OPC UA",
                Method: "CheckVariablesCounter",
                Parameters: parameters
            });
    }

    async MQTTCheckConnection(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "MQTT",
                Method: "CheckConnection",
                Parameters: parameters
            });
    }

    async MQTTBrowseItems(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "MQTT",
                Method: "BrowseItems",
                Parameters: parameters
            });
    }
    async s7CheckConnection(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "S7",
                Method: "CheckConnection",
                Parameters: parameters
            });
    }

    async vijeoCheckConnection(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "VijeoDesigner",
                Method: "CheckConnection",
                Parameters: parameters
            });
    }

    async vijeoBrowseItems(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "VijeoDesigner",
                Method: "BrowseItems",
                Parameters: parameters
            });
    }

    async vijeoBrowseEvents(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "VijeoDesigner",
                Method: "BrowseEvents",
                Parameters: parameters
            });
    }

    async getTemplate(templateName) {
        return await utils.PostQuery('equipments.GetTemplate',
            {
                Template: templateName,
            });
    }

    async getManagerConfiguration(managerUrl) {
        return await utils.PostQuery('equipments.GetManagerConfiguration',
            {
                Url: managerUrl,
            });
    }

    async createProductysManagerTarget(line, target, entity, realTime, dataSourceTemplate, dataSource) {
        return await utils.PostQuery('equipments.CreateProductysManagerTarget',
            {
                Line: line,
                Target: target,
                Entity: entity,
                RealTime: realTime,
                DataSourceTemplate: dataSourceTemplate,
                DataSource: dataSource
            });
    }

    async getProductysManagerLines(url, entity, line) {
        return await utils.PostQuery('equipments.GetProductysManagerLines',
            {
                Url: url,
                Entity: entity,
                Line: line
            });
    }

    async duplicateEquipement(newEq, oldEq, projectId) {
        return await utils.PostQuery('equipments.duplicateEquipement',
            {
                NewEquipmentName: newEq,
                OldEquipmentName: oldEq,
                Id: projectId
            });
    }

    async EluCloudCheckConnection(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "EluCloud",
                Method: "CheckConnection",
                Parameters: parameters
            });
    }
    async EluCloudBrowseMachines(parameters) {
        return await utils.PostQuery('equipments.EquipmentMethod',
            {
                EquipmentType: "EluCloud",
                Method: "BrowseMachines",
                Parameters: parameters
            });
    }
    async PatliteCheckConnection(parameters){
        return await utils.PostQuery('equipments.EquipmentMethod',
        {
            EquipmentType: "Patlite",
            Method: "CheckConnection",
            Parameters: parameters
        });
    }
    async PatliteDiscovery(parameters){
        return await utils.PostQuery('equipments.EquipmentMethod',
        {
            EquipmentType: "Patlite",
            Method: "Discovery",
            Parameters: parameters
        });
    }
    async EsperaCheckConnection(parameters){
        return await utils.PostQuery('equipments.EquipmentMethod',
        {
            EquipmentType: "Espera",
            Method: "CheckConnection",
            Parameters: parameters
        });
    }
}

export default new ProdComEquipmentService();
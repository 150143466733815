<template>
    <b-modal id="newEquipment" v-b-modal.modal-multi-1 size="xxl" @cancel="onCancel" @ok="onOk" @show="onShow" :ok-disabled="!valid" :title="$t('New Equipment')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-card class="prevent-select">
            <b-form ref="mainEquipmentForm">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('Equipment name') + ' *'" label-for="newEquipmentName">
                            <b-form-input id="newEquipmentName" v-model="equipment.Name" :state="validateName()" />
                            <b-form-invalid-feedback id="newEquipmentName-feedback">{{ equipmentNameFeedback }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('Communication type') + ' *'" label-for="newEquipmentType">
                            <b-form-select id="newEquipmentType" v-model="equipment.type" :options="equipmentTypes" :state="validateType()" />
                            <b-form-invalid-feedback id="newEquipmentType-feedback">{{ $t('Value should be selected') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <Equipment :equipment="equipment" :project="selectedProject" ref="config" />
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-modal>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';
// import ConfigOmronFins from '@/components/configuration/Equipments/Configuration_OmronFins.vue';
// import ConfigOPCUA from '@/components/configuration/Equipments/Configuration_OPCUA.vue';
// import ConfigOPCDA from '@/components/configuration/Equipments/Configuration_OPCDA.vue';
// import ConfigS7 from '@/components/configuration/Equipments/Configuration_S7.vue';
// import ConfigVirtual from '@/components/configuration/Equipments/Configuration_Virtual.vue';
// import ConfigModbus from '@/components/configuration/Equipments/Configuration_Modbus.vue';
// import ConfigProductysManager from '@/components/configuration/Equipments/Configuration_ProductysManager.vue';
import Equipment from '@/components/configuration/Equipments/Equipment.vue';

/**
 * The equipment creation wizard. Dynamically loads configuration based on
 * the selected types, and asks the server to create the equipment when
 * validated.
 */
export default {
    name: 'EquipmentWizard',
    components: {
        // ConfigOmronFins,
        // ConfigOPCUA,
        // ConfigOPCDA,
        // ConfigS7,
        // ConfigVirtual,
        // ConfigModbus,
        // ConfigProductysManager,
        ToastAlert,
        Equipment,
    },
    props: {
        /**
         * The list of already created equipments, to validate the new equipment name.
         */
        existingEquipments: Array,
        /**
         * The project on which to add the equipment.
         */
        selectedProject: String,
        /**
         * Gets called right after the equipment is created (only when creation is successful).
         */
        onCreate: Function,
        SetLoadingState: Function,
    },
    watch: {
        existingEquipments(newValue, oldValue) {
            this.existingEquipmentsData = newValue;
        },
        selectedProject(newValue, oldValue) {
            this.selectedProjectData = newValue;
        },
    },
    data() {
        return {
            equipmentTypes: [],
            equipment: { Name: 'Equipement 1', ProjectId: this.selectedProject, type: null },
            equipmentNameFeedback: '',
            existingEquipmentsData: this.existingEquipments,
            valid: false,
            selectedProjectData: this.selectedProject,
        };
    },

    methods: {
        /**
         * Validates the wizard.
         * @returns true if valid, false if not.
         */
        validate() {
            return this.validateName() && this.validateType();
        },
        /**
         * Validates the name field of the wizard.
         * @returns true if valid, false if not.
         */
        validateName() {
            if (this.equipment == null) return false;
            if (this.existingEquipmentsData == null) {
                this.equipmentNameFeedback = '';
                this.valid = true;
                return true;
            }
            if (this.equipment.Name == null || this.equipment.Name == '') {
                this.equipmentNameFeedback = this.$t('Equipment name empty');
                this.valid = false;
                return false;
            }
            // TODO: check regexp for valid equipment name: no special characters: only [a-zA-Z0-9], space, '_' should be allowed.
            const result = this.existingEquipmentsData.findIndex((eq) => eq.itemData.text == this.equipment.Name);
            if (result != -1) {
                this.equipmentNameFeedback = this.$t('Equipment name alrady taken');
            }
            this.valid = result === -1;
            return this.valid;
        },
        /**
         * Validates the type field of the wizard.
         * @returns true if valid, false if not.
         */
        validateType() {
            if (this.equipment == null) return false;
            return !(this.equipment.type == null || this.equipment.type == '');
        },
        /**
         * Gets called when exiting the wizard in any other way than by clicking 'Ok'
         * (by clicking 'Cancel', the top cross or outside the modal.)
         */
        async onCancel(e) {
            this.equipment = { Name: 'Equipement 1', ProjectId: this.selectedProjectData, type: null };
        },
        /**
         * Gets called when the user clicks on the 'Ok' button. Validates the configuration
         * and asks the server to create the equipment.
         */
        async onOk(e) {
            e.preventDefault();
            if (this.$refs.config != null && this.$refs.config.validate != null) {
                const result = await this.$refs.config.validate();
                if (result == null) return;
            }

            if (this.$refs.config != null) await this.$refs.config.synchronizeEquipment();
            if (this.SetLoadingState != null) this.SetLoadingState(true);
            this.equipment.ProjectId = this.selectedProjectData;
            this.$nextTick(() => {
                this.$bvModal.hide('newEquipment');
            });
            const data = await ProdComEquipmentService.createNewEquipment(this.equipment, this.selectedProjectData);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                // Equipment has been created, we can close the wizard and refresh the configuration on the backend and on the frontend.
                if (this.onCreate != null) this.onCreate();
                // Reset the equipment of this wizard for future use.
                this.equipment = { Name: 'Equipement 1', type: null, ProjectId: this.selectedProjectData };
            }
            if (this.SetLoadingState != null) this.SetLoadingState(false);
        },
        /**
         * Gets called when the wizard is shown.
         */
        async onShow(e) {
            // Auto create new equipment name based on existing equipment names.
            var i = 1;
            var resultNames = this.existingEquipmentsData.map((r) => r.itemData.text);
            while (resultNames.findIndex((r) => r === `${this.$t('Device')} ${i}`) != -1) {
                i++;
            }
            this.equipment.Name = `${this.$t('Device')} ${i}`;
            await this.getAvailableEquipmentTypes();
            if (this.equipment.type == null) {
                this.equipment.type = this.equipmentTypes[0].value;
            }
        },
        /**
         * Queries the server for the list of available equipment types.
         */
        async getAvailableEquipmentTypes() {
            const data = await ProdComEquipmentService.getAvailableEquipmentTypes();
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.equipmentTypes = [];
                for (const type of data.ret) {
                    if (type == 'FanucFocas' ) {
                        // Not yet implemented: disable selection.
                        this.equipmentTypes.push({ value: type, text: this.$t(`equipments.types.${type}`), disabled: true });
                    } else {
                        this.equipmentTypes.push({ value: type, text: this.$t(`equipments.types.${type}`) });
                    }
                }
                this.equipmentTypes.sort(function (a, b) {
                    if (a.text < b.text) return -1;
                    if (a.text > b.text) return 1;
                    return 0;
                });
            }
        },
        async validateConfig(value) {
            this.valid = value;
        },
    },
};
</script>